.dialog-parent{
    background-color:#202020;
    width: 100%!important;
    height: fit-content !important;
    color: white;
    position: relative;

}

.dialog-sub-parent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 0; 
}

@media(max-width:800px){
    .dialog-sub-parent{
      
        padding:0 0 3rem 0;
    }
    
}

.detail-paragraph{
    width: 80%;
    text-align: justify;
    color: #8c8c8c;
    font-size: large;
}

.close-icon{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
    background-color: #202020;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .3rem;
}

.dialog-top{
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 10;
    background-color:#181717;

    
}

.person-detail-image{
    width: 18rem;
    height: 18rem;
    background-position: center;
    background-size: cover;
    border-radius: 1rem;
}

.person-detail-parent{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.person-detail-information{ 
    padding: 0 1rem;
    color: white;
}
.person-detial-info-full-name{
    text-align: start ;

}
.nickname{
    font-size: 1.2rem;
}

@media(max-width:800px){
    .person-detial-info-full-name{
        text-align: center ;
    
    }
    .person-detail-parent{
       flex-direction: column;
    align-items: center;

    }
    .person-detail-information{ 
        padding: 0 1rem;
        color: white;
        text-align: center;
    }
}

.close-icon{
    position: fixed;
    top: 2rem;
    right: 2rem;
    color: white;
}