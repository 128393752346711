.header-nav{
    max-width: 300px;
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.529);
    padding: 0.5rem 1rem;
    color: whitesmoke;  
    position: absolute;
    left: 1rem;
    top: 1rem;
    display: flex;
    gap: .5rem;
    font-size: small;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}
.header-nav p{
   margin-top: 0;
   margin-bottom: 0;
}
.back-arrow{
    cursor: pointer;
}
 