body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-color: #272b4d;
  user-select: none;
}

.dim-background {
  background-color: white;
  width: 100dvw;
  height: 110vh;
  position: fixed;
  z-index: -1;
  background-position: center;
  background-size: contain;
  opacity: 0.5;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.test-color {
  color: #fd9b93;
  color: #fe6e9e;
  color: #272b4d;
  color: #171717d5;
  color: rgb(0, 0, 0);
}

.family-tree-background {
}


.--flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
}

.avatar{
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-direction: column;
  width: fit-content;
  gap: 5px;
  cursor: pointer;
}
.opened-trunk-image{
  box-shadow: 0px 10px 10px -6px darkmagenta;
}
.opened-trunk-name{
  background-color: #272b4d !important;
  color: white !important;
}
h1{
  color: white;
  text-align: center;
}


.--no-padding{
  padding: 0;
  margin: 0;
}


.avatar-name{ 
  background-color: white; 
  border-radius: 3px;
  padding: .2rem .4rem;
  font-size: small;
  text-align: center;
}

.mobile-avatar-name{
  display: none; 
}


.parent-size-parent{
  height: 85dvh;
  width: 85dvw;
  /* position: relative; */
}
@media(max-width:800px){

  .avatar-name{ 
    display: none;
  }
  .mobile-avatar-name{
    display: inline-block;
  }
  .parent-size-parent{
    height: 95dvh;
  }
  .gap-big{
    align-items: flex-end;
  }
}

.family-tree-background{
 
}

.gap-big{
  gap:1rem
}
